<template>
  <div class="inner-box">
    <aside-nav
      :currentSubjectIndex="currentSubjectIndex"
      :subjectArr="subjectArr"
    ></aside-nav>

    <div class="mainContent">
      <road-lines
        :subjectArr="subjectArr"
        :roadmap="roadmap"
        :currentSubjectIndex="currentSubjectIndex"
      ></road-lines>

      <video-list
        :subjectArr="subjectArr"
        :roadmap="roadmap"
        :currentSubjectIndex="currentSubjectIndex"
        :subjectId="subjectId"
      ></video-list>
    </div>
  </div>
</template>

<script>
import { getRoadMap } from "network/home";
import AsideNav from "./childComp/AsideNav";
import RoadLines from "./childComp/RoadLines.vue";
import VideoList from "./childComp/VideoList.vue";

import { mapState } from "vuex";

export default {
  name: "Python",
  components: {
    RoadLines,
    VideoList,
    AsideNav,
  },
  data() {
    return {
      subjectData: {},
      roadmap: [],

      // 学科路由的区别修改这里就行
      currentSubjectIndex: 3,
      subjectId: 1,
    };
  },
  computed: {
    ...mapState({
      subjectArr: (state) => state.sj.subjectArr,
    }),
  },
  methods: {
    async getRoadMap() {
      const res = await getRoadMap(this.subjectId);
      this.roadmap = res.data;
    },
  },
  mounted() {
    this.subjectData = this.subjectArr[this.currentSubjectIndex];
    this.subjectId = this.subjectArr[this.currentSubjectIndex].id;
    this.getRoadMap();
  },
};
</script>

<style scoped>
@import url("./common.css");
</style>